<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
    v-if="filteredData"
  />
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListPage from "@/components/penal-retail-helpers/ListPage";

import { db } from "@/firebase/firebaseConfig";

let collectionName = "issues";

export default {
  components: {
    ListPage,
  },
  created() {
    if (this.isSuperCustomer) {
      this.$bind(
        "companies",
        db
          .collection(
            `customers/${this.$store.state.AppActiveUser.customer.id}/companies`
          )
          .where("deleted", "==", false),
        { wait: true }
      );
    }
  },
  computed: {
    ...mapGetters([
      "allIssues",
      "allCustomers",
      "allEstablishments",
      "allLawyers",
    ]),
    isLawyer() {
      return ["lawyer"].includes(this.$store.state.AppActiveUser.role);
    },
    isSuperCustomer() {
      return ["supercustomer"].includes(this.$store.state.AppActiveUser.role);
    },
    isCustomerUser() {
      return ["customer", "supercustomer"].includes(
        this.$store.state.AppActiveUser.role
      );
    },
    penalCustomers() {
      return this.allCustomers.filter((c) =>
        c.casesTypes.map((c) => c.id).includes("penal")
      );
    },
    establishments() {
      if (this.appliedFilters.customer) {
        return this.allEstablishments
          .filter((x) => x.customer.id === this.appliedFilters.customer.id)
          .map((x) => {
            return x.id === "empresa"
              ? {
                  ...x,
                  id: x.id,
                  alias: this.$t("establishments.labels.wholeCompany"),
                }
              : x;
          });
      } else if (this.isCustomerUser) {
        return this.allEstablishments.map((x) => {
          return x.id === "empresa"
            ? {
                ...x,
                id: x.id,
                alias: this.$t("establishments.labels.wholeCompany"),
              }
            : x;
        });
      } else {
        return [];
      }
    },
    filteredData() {
      let filteredData = this.allIssues;

      ["customer", "establishment", "lawyer"].forEach((a) => {
        if (this.appliedFilters[a]) {
          filteredData = filteredData.filter(
            (x) => x[a].id === this.appliedFilters[a].id
          );
        }
      });

      if (this.appliedFilters.company) {
        const companyEstablishments = this.allEstablishments
          .filter(
            (x) => (x.company || {}).id === this.appliedFilters.company.id
          )
          .map((x) => x.id);
        filteredData = filteredData.filter(
          (c) =>
            c.establishment &&
            companyEstablishments.includes(c.establishment.id)
        );
      }

      return filteredData;
    },
    filters() {
      let filters = [];
      filters.push({ size: "1/3", mobileSize: "1/2", search: true });
      if (!this.isCustomerUser) {
        filters.push({
          size: "1/6",
          mobileSize: "1/2",
          select: true,
          placeholder: `${collectionName}.placeholders.customer`,
          label: "alias",
          clearable: true,
          resetOnOptionsChange: true,
          onChange: (e) => {
            this.updateFilters("customer", e);
          },
          options: () => this.penalCustomers,
        });
      }

      if (this.companies.length) {
        filters.push({
          size: "1/6",
          mobileSize: "1/2",
          select: true,
          placeholder: `${collectionName}.placeholders.company`,
          label: "alias",
          clearable: true,
          resetOnOptionsChange: true,
          onChange: (e) => {
            this.updateFilters("company", e);
          },
          options: () => this.companies,
        });
      }

      filters.push({
        size: "1/6",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.establishment`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("establishment", e);
        },
        disabled: !this.isCustomerUser && !this.appliedFilters.customer,
        options: () => this.establishments,
      });
      filters.push({
        size: "1/6",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.lawyer`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("lawyer", e);
        },
        options: () => this.allLawyers,
      });

      filters.push({
        size: this.isCustomerUser && !this.companies.length ? "1/3" : "1/6",
        mobileSize: "1/2",
        button: true,
        i18n: `${collectionName}.buttons.new`,
        extraClasses: "text-right",
        to: { name: "main-issues-new" },
      });

      return filters;
    },
  },
  data() {
    let columns = [];

    const formatDatetime = (data) => {
      return (
        this.$moment(data.value.toDate()).format("DD-MM-YYYY HH:mm") || null
      );
    };

    const valueGetterAlias = (key) => {
      return (p) => p.data[key].alias;
    };

    columns.push({
      headerName: this.$t(`${collectionName}.columns.cid`),
      field: "cid",
      filter: false,
      width: 50,
    });
    columns.push({
      headerName: this.$t(`${collectionName}.columns.datetime`),
      field: "datetime",
      filter: false,
      width: 75,
      valueFormatter: formatDatetime,
    });

    (["customer", "supercustomer"].includes(
      this.$store.state.AppActiveUser.role
    )
      ? ["establishment", "lawyer"]
      : ["customer", "establishment", "lawyer"]
    ).forEach((a) => {
      columns.push({
        headerName: this.$t(`${collectionName}.columns.${a}`),
        field: a,
        filter: false,
        width: 75,
        valueGetter: valueGetterAlias(a),
        filterValueGetter: valueGetterAlias(a),
      });
    });

    return {
      companies: [],
      appliedFilters: {
        customer: null,
        company: null,
        establishment: null,
        lawyer: null,
      },
      data: null,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  methods: {
    updateFilters(key, value) {
      if (this.appliedFilters[key] !== value) {
        this.appliedFilters[key] = value;
      }
    },
    onRowClicked(params) {
      this.$router
        .push({ name: "main-issues-edit", params: { id: params.data.id } })
        .catch(() => {});
    },
  },
};
</script>
